import { useGetInteractionschemesQuery } from "./interactionschemesApiSlice"
import Interactionscheme from "./Interactionscheme"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { useNavigate } from "react-router-dom"

const InteractionschemesList = () => {
    useTitle('techInteractionschemes: Interactionschemes List')

    const { username, isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    const newInteractionschemeClickedHandler = () => {
        navigate("/dash/fishflow/interactionschemes/new")
    }

    const {
        data: interactionschemes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetInteractionschemesQuery('interactionschemesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = interactionschemes

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(interactionschemeId => entities[interactionschemeId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map((interactionschemeId, index) => <Interactionscheme key={interactionschemeId} interactionschemeId={interactionschemeId} index={index} />)

        content = (
            <section className="">
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <span className="font-bold text-2xl text-gray-600">Interaction Schemes</span>
                    </div>
                    <button 
                        className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                        onClick={newInteractionschemeClickedHandler}
                    >
                        New Interaction Scheme
                    </button>
                </div>
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <table className="table table--interactionschemes">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th interactionscheme__status">No</th>
                                <th scope="col" className="table__th interactionscheme__title">Title</th>
                                <th scope="col" className="table__th interactionscheme__status">Response Type</th>
                                <th scope="col" className="table__th interactionscheme__status">is Active</th>
                                <th scope="col" className="table__th interactionscheme__created">Created</th>
                                <th scope="col" className="table__th interactionscheme__updated">Updated</th>
                                <th scope="col" className="table__th interactionscheme__username">Owner</th>
                                <th scope="col" className="table__th interactionscheme__edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </section>
        )
    }

    return content
}
export default InteractionschemesList