import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice"

const datasheetsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = datasheetsAdapter.getInitialState()

export const datasheetsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDatasheets: builder.query({
            query: () => ({
                url: '/datasheets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedDatasheets = responseData.map(datasheet => {
                    datasheet.id = datasheet._id
                    return datasheet
                });
                return datasheetsAdapter.setAll(initialState, loadedDatasheets)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Datasheet', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Datasheet', id }))
                    ]
                } else return [{ type: 'Datasheet', id: 'LIST' }]
            }
        }),
        addNewDatasheet: builder.mutation({
            query: initialDatasheet => ({
                url: '/datasheets',
                method: 'POST',
                body: {
                    ...initialDatasheet,
                }
            }),
            invalidatesTags: [
                { type: 'Datasheet', id: "LIST" }
            ]
        }),
        updateDatasheet: builder.mutation({
            query: initialDatasheet => ({
                url: '/datasheets',
                method: 'PATCH',
                body: {
                    ...initialDatasheet,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Datasheet', id: arg.id }
            ]
        }),
        deleteDatasheet: builder.mutation({
            query: ({ id }) => ({
                url: `/datasheets`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Datasheet', id: arg.id }
            ]
        }),
        createNewInteractionsheet: builder.mutation({
            query: initialDatasheet => ({
                url: '/documentmigrations/interactionschemetointeractionsheet',
                method: 'POST',
                body: {
                    ...initialDatasheet,
                }
            }),
            invalidatesTags: [
                { type: 'Datasheet', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useGetDatasheetsQuery,
    useAddNewDatasheetMutation,
    useUpdateDatasheetMutation,
    useDeleteDatasheetMutation,
    useCreateNewInteractionsheetMutation,
} = datasheetsApiSlice

// returns the query result object
export const selectDatasheetsResult = datasheetsApiSlice.endpoints.getDatasheets.select()

// creates memoized selector
const selectDatasheetsData = createSelector(
    selectDatasheetsResult,
    datasheetsResult => datasheetsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllDatasheets,
    selectById: selectDatasheetById,
    selectIds: selectDatasheetIds
    // Pass in a selector that returns the datasheets slice of state
} = datasheetsAdapter.getSelectors(state => selectDatasheetsData(state) ?? initialState)