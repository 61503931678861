import React from 'react';

function parseTableFromText(text) {
    // Extract the table part from the text
    const tableText = text.split('Summary Table:')[1].trim();
  
    // Split the table into rows
    const rows = tableText.split('\n').filter(row => row.includes('|'));
  
    // Split each row into columns
    const parsedRows = rows.map(row =>
      row.split('|').map(cell => cell.trim()).filter(cell => cell)
    );
  
    return parsedRows;
  }
  

const InteractionsheetResultReading = ({ text }) => {
//   // Parse the table from the provided text
//   const tableData = parseTableFromText(text);

//   // The first row is the header
//   const headers = tableData[0];
//   // The remaining rows are the body
//   const rows = tableData.slice(1);

//   return (
//     <div className="overflow-x-auto">
//       <table className="min-w-full border-collapse border border-gray-300">
//         <thead>
//           <tr className="bg-blue-500 text-white">
//             {headers.map((header, index) => (
//               <th key={index} className="border border-gray-300 px-4 py-2 text-left">
//                 {header}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((row, rowIndex) => (
//             <tr key={rowIndex} className="bg-white text-gray-800">
//               {row.map((cell, cellIndex) => (
//                 <td key={cellIndex} className="border border-gray-300 px-4 py-2">
//                   {cell}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
  // Split the text by lines
  const lines = text.split('\n');

  // Function to check if a line is part of the table
  const isTableLine = (line) => line.includes('|');

  // Separate paragraphs and table content
  const content = [];
  let currentTable = [];

  lines.forEach((line) => {
    if (isTableLine(line)) {
      currentTable.push(line);
    } else {
      if (currentTable.length) {
        content.push({ type: 'table', data: currentTable });
        currentTable = [];
      }
      if (line.trim()) {
        content.push({ type: 'paragraph', data: line.trim() });
      }
    }
  });

  if (currentTable.length) {
    content.push({ type: 'table', data: currentTable });
  }

  // Function to parse table rows
  const parseTable = (tableLines) => {
    const rows = tableLines.map((row) =>
      row.split('|').map((cell) => cell.trim()).filter(Boolean)
    );
    return rows;
  };

  return (
    <div className="p-4 space-y-4">
      {content.map((item, index) => {
        if (item.type === 'paragraph') {
          return (
            <p key={index} className="text-gray-800 text-base leading-relaxed">
              {item.data}
            </p>
          );
        }

        if (item.type === 'table') {
          const tableData = parseTable(item.data);
          const headers = tableData[0];
          const rows = tableData.slice(1);

          return (
            <div key={index} className="overflow-x-auto">
              <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-blue-500 text-white">
                    {headers.map((header, i) => (
                      <th key={i} className="border border-gray-300 px-4 py-2 text-left">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex} className="bg-white text-gray-800">
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="border border-gray-300 px-4 py-2">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default InteractionsheetResultReading;
