import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetSj62renovationsQuery } from './sj62renovationsApiSlice'
import { memo } from 'react'
import { formatToRupiah } from "../utils/formatToRupiah";

const Sj62renovation = ({ sj62renovationId, index }) => {

    const { sj62renovation } = useGetSj62renovationsQuery("sj62renovationsList", {
        selectFromResult: ({ data }) => ({
            sj62renovation: data?.entities[sj62renovationId]
        }),
    })

    const navigate = useNavigate()

    if (sj62renovation) {
        const created = new Date(sj62renovation.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(sj62renovation.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/sj62renovations/${sj62renovationId}`)

        return (
            <tr className="table__row">
                <td className="table__cell sj62renovation__title">{index + 1}</td>
                <td className="table__cell sj62renovation__title">{sj62renovation.task}</td>
                <td className="table__cell sj62renovation__title">{sj62renovation.area}</td>
                <td className="table__cell sj62renovation__title">{sj62renovation.priority}</td>
                <td className="table__cell sj62renovation__username">{sj62renovation.vendor}</td>
                <td className="text-right">{formatToRupiah(sj62renovation.cost)}</td>
                <td className="text-right">{formatToRupiah(sj62renovation.budget)}</td>
                <td className="table__cell sj62renovation__created">{created}</td>
                <td className="table__cell sj62renovation__updated">{updated}</td>
                <td className="table__cell sj62renovation__username">{sj62renovation.status}</td>
                <td className="table__cell sj62renovation__status">
                    {sj62renovation.completed
                        ? <span className="sj62renovation__status--completed">Completed</span>
                        : <span className="sj62renovation__status--open">Open</span>
                    }
                </td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedSj62renovation = memo(Sj62renovation)

export default memoizedSj62renovation