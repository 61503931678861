import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice"

const interactionsheetsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = interactionsheetsAdapter.getInitialState()

export const interactionsheetsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInteractionsheets: builder.query({
            query: () => ({
                url: '/interactionsheets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedInteractionsheets = responseData.map(interactionsheet => {
                    interactionsheet.id = interactionsheet._id
                    return interactionsheet
                });
                return interactionsheetsAdapter.setAll(initialState, loadedInteractionsheets)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Interactionsheet', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Interactionsheet', id }))
                    ]
                } else return [{ type: 'Interactionsheet', id: 'LIST' }]
            }
        }),
        addNewInteractionsheet: builder.mutation({
            query: initialInteractionsheet => ({
                url: '/interactionsheets',
                method: 'POST',
                body: {
                    ...initialInteractionsheet,
                }
            }),
            invalidatesTags: [
                { type: 'Interactionsheet', id: "LIST" }
            ]
        }),
        updateInteractionsheet: builder.mutation({
            query: initialInteractionsheet => ({
                url: '/interactionsheets',
                method: 'PATCH',
                body: {
                    ...initialInteractionsheet,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Interactionsheet', id: arg.id }
            ]
        }),
        deleteInteractionsheet: builder.mutation({
            query: ({ id }) => ({
                url: `/interactionsheets`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Interactionsheet', id: arg.id }
            ]
        }),
        updateAiAnalysisInteractionsheet: builder.mutation({
            query: initialInteractionsheet => ({
                url: '/interactionsheets/analysisbyopenai35',
                method: 'POST',
                body: {
                    ...initialInteractionsheet,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Interactionsheet', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetInteractionsheetsQuery,
    useAddNewInteractionsheetMutation,
    useUpdateInteractionsheetMutation,
    useDeleteInteractionsheetMutation,
    useUpdateAiAnalysisInteractionsheetMutation,
} = interactionsheetsApiSlice

// returns the query result object
export const selectInteractionsheetsResult = interactionsheetsApiSlice.endpoints.getInteractionsheets.select()

// creates memoized selector
const selectInteractionsheetsData = createSelector(
    selectInteractionsheetsResult,
    interactionsheetsResult => interactionsheetsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInteractionsheets,
    selectById: selectInteractionsheetById,
    selectIds: selectInteractionsheetIds
    // Pass in a selector that returns the interactionsheets slice of state
} = interactionsheetsAdapter.getSelectors(state => selectInteractionsheetsData(state) ?? initialState)