import { useGetInteractionsheetsQuery } from "./interactionsheetsApiSlice"
import Interactionsheet from "./Interactionsheet"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { useNavigate } from "react-router-dom"

const InteractionsheetsList = () => {
    useTitle('techInteractionsheets: Interactionsheets List')

    const { username, isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    const newDatasheetClickedHandler = () => {
        navigate("/dash/fishflow/datasheets")
    }

    const {
        data: interactionsheets,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetInteractionsheetsQuery('interactionsheetsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = interactionsheets

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(interactionsheetId => entities[interactionsheetId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map((interactionsheetId, index) => <Interactionsheet key={interactionsheetId} interactionsheetId={interactionsheetId} index={index}/>)

        content = (
            <section className="">
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <span className="font-bold text-2xl text-gray-600">Interaction Sheets</span>
                    </div>
                    <button 
                        className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                        onClick={newDatasheetClickedHandler}
                    >
                        Create New Interaction sheet from Data Sheet
                    </button>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <table className="table table--interactionsheets">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th interactionsheet__title">No</th>
                                <th scope="col" className="table__th interactionsheet__title">Title</th>
                                <th scope="col" className="table__th interactionsheet__status">is Published</th>
                                <th scope="col" className="table__th interactionsheet__status">status</th>
                                <th scope="col" className="table__th interactionsheet__created">Created</th>
                                <th scope="col" className="table__th interactionsheet__updated">Updated</th>
                                <th scope="col" className="table__th interactionsheet__username">Owner</th>
                                {/* <th scope="col" className="table__th interactionsheet__username">aiResponse</th> */}
                                <th scope="col" className="table__th interactionsheet__edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </div>

            </section>
        )
    }

    return content
}
export default InteractionsheetsList