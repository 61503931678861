import { useState, useEffect } from "react"
import { useUpdateInteractionsheetMutation, useDeleteInteractionsheetMutation, useUpdateAiAnalysisInteractionsheetMutation } from "./interactionsheetsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../../hooks/useAuth"
import InteractionsheetResultReading from "./InteractionsheetResultReading"

const EditInteractionsheetForm = ({ interactionsheet, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateInteractionsheet, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateInteractionsheetMutation()

    const [deleteInteractionsheet, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteInteractionsheetMutation()

    const [updateAiAnalysisInteractionsheet, {
        isLoading: isLoadingUpdateAi,
        isSuccess: isSuccessUpdateAi,
        isError: isErrorUpdateAi,
        error: errorUpdateAi
    }] = useUpdateAiAnalysisInteractionsheetMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(interactionsheet.title)
    const [contentSource, setContentSource] = useState(interactionsheet.contentSource)
    const [isTransformed, setIsTransformed] = useState(interactionsheet.isTransformed)
    const [contentArticle1, setContentarticle1] = useState(interactionsheet.contentArticle1)
    const [aiResponse, setAiResponse] = useState(interactionsheet.aiResponse)
    const [userId, setUserId] = useState(interactionsheet.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess || isSuccessUpdateAi) {
            setTitle('')
            setContentSource('')
            setUserId('')
            navigate('/dash/fishflow/interactionsheets')
        }

    }, [isSuccess, isDelSuccess, isSuccessUpdateAi, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onContentSourceChanged = e => setContentSource(e.target.value)
    const onIsTransformedChanged = e => setIsTransformed(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, contentSource, userId].every(Boolean) && !isLoading
    // const canUpdateAiAnalysis = [title, text, userId].every(Boolean) && !isLoading
    const canUpdateAiAnalysis = true

    const onSaveInteractionsheetClicked = async (e) => {
        if (canSave) {
            await updateInteractionsheet({ id: interactionsheet.id, user: userId, title, contentSource, isTransformed })
        }
    }

    const onDeleteInteractionsheetClicked = async () => {
        await deleteInteractionsheet({ id: interactionsheet.id })
    }

    const onUpdateAiAnalysisInteractionsheetClicked = async (e) => {
        if (canUpdateAiAnalysis) {
            await updateAiAnalysisInteractionsheet({ id: interactionsheet.id, user: userId, title, contentSource, isTransformed })
        }
    }

    const created = new Date(interactionsheet.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(interactionsheet.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !contentSource ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="bg-red-400 p-2 rounded-md text-blue-800 font-bold ml-5"
                title="Delete"
                onClick={onDeleteInteractionsheetClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> Delete
            </button>
        )
    }

    let updateAiResponseButton = null
    if (isManager || isAdmin) {
        updateAiResponseButton = (
            <button
                className="bg-green-400 p-2 rounded-md text-blue-800 font-bold mr-5"
                title="Delete"
                onClick={onUpdateAiAnalysisInteractionsheetClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> AI Analysis
            </button>
        )
    }

    let publishAnalysisButton = null
    if (isManager || isAdmin) {
        publishAnalysisButton = (
            <button
                className="bg-green-400 p-2 rounded-md text-blue-800 font-bold mr-5"
                title="Delete"
                // onClick={onDeleteInteractionsheetClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> Publish Analysis
            </button>
        )
    }

    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <h2 className="font-bold text-2xl text-gray-600">Edit Interactionsheet #{interactionsheet.ticket}</h2>
                    </div>
                    <div className="form__action-buttons">
                        {publishAnalysisButton}
                        {updateAiResponseButton}
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            onClick={onSaveInteractionsheetClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        {deleteButton}
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="" htmlFor="">Title:</label>
                    <label className="" htmlFor="">{title}</label>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="">contentarticle1:</label>
                    <label className="whitespace-pre-line">{contentArticle1}</label>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    AI Analysis: 
                    <p className="mt-5 whitespace-pre-line">    
                    <InteractionsheetResultReading text={aiResponse} />
                    {/* {aiResponse} */}
                    </p>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="form__divider">
                        <label className="form__label form__checkbox-container" htmlFor="interactionsheet-completed">
                            Is Transformed:
                            <input
                                className="form__checkbox"
                                id="interactionsheet-completed"
                                name="completed"
                                type="checkbox"
                                checked={isTransformed}
                                onChange={onIsTransformedChanged}
                            />
                        </label>

                        <div className="mt-5">
                            <label className="form__label form__checkbox-container" htmlFor="interactionsheet-username">
                                ASSIGNED TO:</label>
                            <select
                                id="interactionsheet-username"
                                name="username"
                                className="form__select"
                                value={userId}
                                onChange={onUserIdChanged}
                            >
                                {options}
                            </select>                                                   
                        </div>

                    </div>
                    <div className="form__divider">
                        <p className="form__created">Created:<br />{created}</p>
                        <p className="form__updated">Updated:<br />{updated}</p>
                    </div>
                </div>
            </form>
        </>
    )

    return content
}

export default EditInteractionsheetForm