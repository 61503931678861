import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewSj62renovationMutation } from "./sj62renovationsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewSj62renovationForm = ({ users }) => {

    const [addNewSj62renovation, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewSj62renovationMutation()

    const navigate = useNavigate()

    const [task, setTask] = useState("")
    const [priority, setPriority] = useState("urgent")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("new")
    const [cost, setCost] = useState(0)
    const [budget, setBudget] = useState(0)
    const [vendor, setVendor] = useState("")
    const [material, setMaterial] = useState("")
    const [area, setArea] = useState("unassigned")
    const [productcode, setProductcode] = useState("0000000000")
    const [qty, setQty] = useState(1)
    const [unitcost, setUnitcost] = useState(0)
    const [completed, setCompleted] = useState(false)
    // const [userId, setUserId] = useState(users[0].id)
    const [userId, setUserId] = useState("653f54be13d7ab2243a410f2") //force to admin

    useEffect(() => {
        if (isSuccess) {
            setTask('')
            setDescription('')
            setUserId('')
            navigate('/dash/sj62renovations')
        }
    }, [isSuccess, navigate])

    const onTaskChanged = e => setTask(e.target.value)
    const onPriorityChanged = e => setPriority(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)
    const onCostChanged = e => setCost(e.target.value)
    const onBudgetChanged = e => setBudget(e.target.value)
    const onVendorChanged = e => setVendor(e.target.value)
    const onMaterialChanged = e => setMaterial(e.target.value)
    const onAreaChanged = e => setArea(e.target.value)
    const onProductcodeChanged = e => setProductcode(e.target.value)
    const onQtyChanged = e => setQty(e.target.value)
    const onUnitcostChanged = e => setUnitcost(e.target.value)
    const onCompletedChanged = e => setCompleted(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [task, description, userId].every(Boolean) && !isLoading

    const onSaveSj62renovationClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewSj62renovation({ user: userId, task, priority, description, status, cost, budget, vendor, material, area, productcode, qty, unitcost, completed })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !task ? "form__input--incomplete" : ''
    const validTextClass = !description ? "form__input--incomplete" : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveSj62renovationClicked}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <h2 className="font-bold text-2xl text-gray-600">New Sj62renovation</h2>
                    <div className="form__action-buttons">
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="title">
                        Task:</label>
                    <input
                        className="w-full p-2 rounded-md"
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={task}
                        onChange={onTaskChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        Description:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        rows={5}
                        id="text"
                        name="text"
                        value={description}
                        onChange={onDescriptionChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        priority:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="text"
                        value={priority}
                        onChange={onPriorityChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        status:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="text"
                        value={status}
                        onChange={onStatusChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        cost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="cost"
                        value={cost}
                        onChange={onCostChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        budget:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="budget"
                        value={budget}
                        onChange={onBudgetChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        vendor:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="vendor"
                        value={vendor}
                        onChange={onVendorChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        material:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        rows={5}
                        id="text"
                        name="text"
                        value={material}
                        onChange={onMaterialChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        area:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={area}
                        onChange={onAreaChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        productcode:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={productcode}
                        onChange={onProductcodeChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        qty:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={qty}
                        onChange={onQtyChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        unitcost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={unitcost}
                        onChange={onUnitcostChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label form__checkbox-container" htmlFor="username">
                        ASSIGNED TO:</label>
                    <select
                        id="username"
                        name="username"
                        className="form__select"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>
            </form>
        </>
    )

    return content
}

export default NewSj62renovationForm