import { useGetSj62renovationsQuery, selectSj62renovationTotals  } from "./sj62renovationsApiSlice"
import Sj62renovation from "./Sj62renovation"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { formatToRupiah } from "../utils/formatToRupiah";
import { useState } from "react";

const Sj62renovationsList = () => {
    useTitle('techSj62renovations: Sj62renovations List')

    const { username, isManager, isAdmin } = useAuth()
    const navigate = useNavigate()
    const newDatasheetClickedHandler = () => {
        navigate("/dash/sj62renovations/new")
    }
    const [taskFilter, setTaskFilter] = useState('');
    const [vendorFilter, setVendorFilter] = useState('');
    const [priorityFilter, setPriorityFilter] = useState('');
    const [areaFilter, setAreaFilter] = useState('');

    const [filteredTotalCost, setFilteredTotalCost] = useState(0)
    const [filteredTotalBudget, setFilteredTotalBudget] = useState(0)
    const [filteredBalance, setFilteredBalance] = useState(0)

    const {
        data: sj62renovations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSj62renovationsQuery('sj62renovationsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    const totals = useSelector(selectSj62renovationTotals);

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = sj62renovations

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(sj62renovationId => entities[sj62renovationId].username === username)
        }


        // Apply task and vendor filters
        if (taskFilter) {
            filteredIds = filteredIds.filter(id => 
                entities[id].task?.toLowerCase().includes(taskFilter.toLowerCase())
            );
        }
        if (vendorFilter) {
            filteredIds = filteredIds.filter(id => 
                entities[id].vendor?.toLowerCase().includes(vendorFilter.toLowerCase())
            );
        }
        if (priorityFilter) {
            filteredIds = filteredIds.filter(id => 
                entities[id].priority?.toLowerCase().includes(priorityFilter.toLowerCase())
            );
        }
        if (areaFilter) {
            filteredIds = filteredIds.filter(id => 
                entities[id].area?.toLowerCase().includes(areaFilter.toLowerCase())
            );
        }

        // Calculate filtered totals
        const filteredTotalCost = filteredIds.reduce(
            (sum, id) => sum + (parseFloat(entities[id].cost) || 0),
            0
        );
        const filteredTotalBudget = filteredIds.reduce(
            (sum, id) => sum + (parseFloat(entities[id].budget) || 0),
            0
        );
        const filteredBalance = filteredTotalBudget - filteredTotalCost;

        const tableContent = ids?.length && filteredIds.map((sj62renovationId, index) => <Sj62renovation key={sj62renovationId} sj62renovationId={sj62renovationId} index={index} />)

        content = (
            <section className="">
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <span className="font-bold text-2xl text-gray-600">SJ6 No.2 Renovation Task List</span>
                    </div>
                    <button 
                        className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                        onClick={newDatasheetClickedHandler}
                    >
                        New Task
                    </button>
                </div>
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <p className="font-bold">Summary</p>
                    <div className="mt-2">
                        <p>Total Budget: {formatToRupiah(totals.totalBudget)}</p>
                        <p>Total Cost: {formatToRupiah(totals.totalCost)}</p>
                        <p>Balance: {formatToRupiah(totals.balance)}</p>
                    </div>

                    <div className="mt-2">
                        <p>Filtered Total Budget: {formatToRupiah(filteredTotalBudget)}</p>
                        <p>Filtered Total Cost: {formatToRupiah(filteredTotalCost)}</p>
                        <p>Filtered Balance: {formatToRupiah(filteredBalance)}</p>
                    </div>
                </div>
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                <div className="flex flex-row justify-between mb-4">
                        <div>
                            <label htmlFor="taskFilter" className="mr-2 font-bold">Task Filter:</label>
                            <input
                                type="text"
                                id="taskFilter"
                                value={taskFilter}
                                onChange={(e) => setTaskFilter(e.target.value)}
                                className="border rounded-md p-1"
                            />
                        </div>
                        <div>
                            <label htmlFor="priorityFilter" className="mr-2 font-bold">Priority Filter:</label>
                            <input
                                type="text"
                                id="priorityFilter"
                                value={priorityFilter}
                                onChange={(e) => setPriorityFilter(e.target.value)}
                                className="border rounded-md p-1"
                            />
                        </div>
                        <div>
                            <label htmlFor="areaFilter" className="mr-2 font-bold">Area Filter:</label>
                            <input
                                type="text"
                                id="areaFilter"
                                value={areaFilter}
                                onChange={(e) => setAreaFilter(e.target.value)}
                                className="border rounded-md p-1"
                            />
                        </div>
                        <div>
                            <label htmlFor="vendorFilter" className="mr-2 font-bold">Vendor Filter:</label>
                            <input
                                type="text"
                                id="vendorFilter"
                                value={vendorFilter}
                                onChange={(e) => setVendorFilter(e.target.value)}
                                className="border rounded-md p-1"
                            />
                        </div>
                    </div>
                    
                    <table className="table table--sj62renovations">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th sj62renovation__status">No</th>
                                <th scope="col" className="table__th sj62renovation__title">task</th>
                                <th scope="col" className="table__th sj62renovation__status">area</th>
                                <th scope="col" className="table__th sj62renovation__status">priority</th>
                                <th scope="col" className="table__th sj62renovation__status">vendor</th>
                                <th scope="col" className="table__th sj62renovation__username">cost</th>
                                <th scope="col" className="table__th sj62renovation__username">budget</th>
                                <th scope="col" className="table__th sj62renovation__created">Created</th>
                                <th scope="col" className="table__th sj62renovation__updated">Updated</th>
                                <th scope="col" className="table__th sj62renovation__updated">status</th>
                                <th scope="col" className="table__th sj62renovation__updated">completed</th>
                                <th scope="col" className="table__th sj62renovation__edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </section>
        )
    }

    return content
}
export default Sj62renovationsList

// import { useGetSj62renovationsQuery, selectSj62renovationTotals } from "./sj62renovationsApiSlice";
// import Sj62renovation from "./Sj62renovation";
// import useAuth from "../../../hooks/useAuth";
// import useTitle from "../../../hooks/useTitle";
// import PulseLoader from "react-spinners/PulseLoader";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { formatToRupiah } from "../utils/formatToRupiah";

// const Sj62renovationsList = () => {
//     useTitle('techSj62renovations: Sj62renovations List');

//     const { username, isManager, isAdmin } = useAuth();
//     const navigate = useNavigate();

//     const { data: response, isLoading, isSuccess, isError, error } = useGetSj62renovationsQuery('sj62renovationsList', {
//         pollingInterval: 60000,
//         refetchOnFocus: true,
//         refetchOnMountOrArgChange: true,
//     });

//     const totals = useSelector(selectSj62renovationTotals);
//     console.log("Totals from Redux Selector:", totals);


//     let content;

//     if (isLoading) content = <PulseLoader color={"#FFF"} />;

//     if (isError) {
//         content = <p className="errmsg">{error?.data?.message}</p>;
//     }

//     if (isSuccess) {
//         const filteredIds = isManager || isAdmin
//             ? response.ids
//             : response.ids.filter(id => response.entities[id].username === username);

//         const tableContent = filteredIds.map((id, index) => (
//             <Sj62renovation key={id} sj62renovationId={id} index={index} />
//         ));

//         content = (
//             <section>
//                 <div className="summary">
//                     <p>Total Budget: {formatToRupiah(totals.totalBudget)}</p>
//                     <p>Total Cost: {formatToRupiah(totals.totalCost)}</p>
//                     <p>Balance: {formatToRupiah(totals.balance)}</p>
//                 </div>
//                 <div>{tableContent}</div>
//             </section>
//         );
//     }

//     return content;
// };

// export default Sj62renovationsList;
