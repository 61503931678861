import { useState, useEffect } from "react"
import { useUpdateInteractionschemeMutation, useDeleteInteractionschemeMutation } from "./interactionschemesApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../../hooks/useAuth"

const EditInteractionschemeForm = ({ interactionscheme, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateInteractionscheme, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateInteractionschemeMutation()

    const [deleteInteractionscheme, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteInteractionschemeMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(interactionscheme.title)
    const [aiPrompt, setAiPrompt] = useState(interactionscheme.aiPrompt)
    const [indicator, setIndicator] = useState(interactionscheme.indicator)
    const [reqContentPattern, setReqContentPattern] = useState(interactionscheme.reqContentPattern)
    const [argument, setArgument] = useState(interactionscheme.argument)
    const [isActive, setIsActive] = useState(interactionscheme.isActive)
    const [userId, setUserId] = useState(interactionscheme.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setAiPrompt('')
            setUserId('')
            navigate('/dash/fishflow/interactionschemes')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onAiPromptChanged = e => setAiPrompt(e.target.value)
    const onIndicatorChanged = e => setIndicator(e.target.value)
    const onIsActiveChanged = e => setIsActive(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    // const canSave = [title, aiPrompt, userId].every(Boolean) && !isLoading
    const canSave = true

    const onSaveInteractionschemeClicked = async (e) => {
        if (canSave) {
            await updateInteractionscheme({ id: interactionscheme.id, user: userId, title, aiPrompt, indicator, isActive })
        }
    }

    const onDeleteInteractionschemeClicked = async () => {
        await deleteInteractionscheme({ id: interactionscheme.id })
    }

    const created = new Date(interactionscheme.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(interactionscheme.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !aiPrompt ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="bg-red-400 p-2 rounded-md text-blue-800 font-bold ml-5"
                title="Delete"
                onClick={onDeleteInteractionschemeClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> Delete
            </button>
        )
    }

    const content = (
        <>
            <p className="bg-red-400">{errContent}</p>

            {console.log(errContent)}
            
            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <h2 className="font-bold text-2xl text-gray-600">Edit Interactionscheme #{interactionscheme.ticket}</h2>
                    </div>
                    <div className="">
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            onClick={onSaveInteractionschemeClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        {deleteButton}
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="font-bold" htmlFor="interactionscheme-title">
                        Title:</label>
                    <input
                        className="w-full p-2 rounded-md"
                        id="interactionscheme-title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="">
                        <label className="font-bold" htmlFor="interactionscheme-text">
                            AI Prompt:</label>
                    </div>
                    <div className="w-full">
                        <textarea
                            // className={`form__input form__input--text ${validTextClass}`}
                            className="w-full p-2 rounded-md"
                            rows={15}
                            id="interactionscheme-text"
                            name="text"
                            value={aiPrompt}
                            onChange={onAiPromptChanged}
                        />
                    </div>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="">
                        <label className="font-bold" htmlFor="interactionscheme-text">
                            Indicator:</label>
                    </div>
                    <div className="w-full">
                        <textarea
                            // className={`form__input form__input--text ${validTextClass}`}
                            className="w-full p-2 rounded-md"
                            rows={15}
                            id="interactionscheme-text"
                            name="text"
                            value={indicator}
                            onChange={onIndicatorChanged}
                        />
                    </div>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="form__divider">
                        <div>
                            <label className="form__label form__checkbox-container" htmlFor="interactionscheme-completed">
                                IS TRANSFORMED:
                                <input
                                    className="form__checkbox"
                                    id="interactionscheme-completed"
                                    name="completed"
                                    type="checkbox"
                                    checked={isActive}
                                    onChange={onIsActiveChanged}
                                />
                            </label>
                        </div>

                        <div>
                            <label className="form__label form__checkbox-container" htmlFor="interactionscheme-username">
                                ASSIGNED TO:</label>
                            <select
                                id="interactionscheme-username"
                                name="username"
                                className="form__select"
                                value={userId}
                                onChange={onUserIdChanged}
                            >
                                {options}
                            </select>
                        </div>

                    </div>
                    <div className="form__divider">
                        <p className="form__created">Created:<br />{created}</p>
                        <p className="form__updated">Updated:<br />{updated}</p>
                    </div>
                </div>
            </form>
        </>
    )

    return content
}

export default EditInteractionschemeForm