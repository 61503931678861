import { useState, useEffect } from "react"
import { useUpdateDatasheetMutation, useDeleteDatasheetMutation, useCreateNewInteractionsheetMutation } from "./datasheetsApiSlice"
import { useGetInteractionschemesQuery } from "../interactionschemes/interactionschemesApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../../hooks/useAuth"

const EditDatasheetForm = ({ datasheet, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateDatasheet, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateDatasheetMutation()

    const [deleteDatasheet, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteDatasheetMutation()

    const [createInteractionsheet, {
        isLoading: isLoadingCreateInteractionsheet,
        isSuccess: isSuccessCreateInteractionsheet,
        isError: isErrorCreateInteractionsheet,
        error: errorCreateInteractionsheet
    }] = useCreateNewInteractionsheetMutation()

    const {
        data: interactionschemes,
        isLoading: isLoadingInteractionschemes,
        isSuccess: isSuccessInteractionschemes,
        isError: isErrorInteractionschemes,
        error: errorInteractionschemes
    } = useGetInteractionschemesQuery('interactionschemesList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const navigate = useNavigate()

    const [title, setTitle] = useState(datasheet.title)
    const [contentSource, setContentSource] = useState(datasheet.contentSource)
    const [contentArticle1, setContentArticle1] = useState(datasheet.contentArticle1)
    const [isTransformed, setIsTransformed] = useState(datasheet.isTransformed)
    const [userId, setUserId] = useState(datasheet.user)

    // additional state
    const [interactionSchemeId, setInteractionSchemeId] = useState("67793936f4e6719e8566de4d")
    const [interactionSheetName, setInteractionSheetName] = useState("Data Minggu Subuh 5 Jan 2025 , 00.24")

    useEffect(() => {

        if (isSuccess || isDelSuccess || isSuccessCreateInteractionsheet) {
            setTitle('')
            setContentSource('')
            setUserId('')
            navigate('/dash/fishflow/datasheets')
        }

    }, [isSuccess, isDelSuccess, isSuccessCreateInteractionsheet, navigate])

    const [selectInteractionscheme, setSelectInteractionscheme] = useState([]);

    useEffect(() => {
        if (isSuccessInteractionschemes) {
            const { ids, entities } = interactionschemes;
            const options = ids.map((interactionschemeId) => (
                <option key={interactionschemeId} value={interactionschemeId}>
                    {entities[interactionschemeId]?.title || interactionschemeId}
                </option>
            ));
            setSelectInteractionscheme(options);
        }
    }, [isSuccessInteractionschemes, interactionschemes]);
    

    const onTitleChanged = e => setTitle(e.target.value)
    const onContentSourceChanged = e => setContentSource(e.target.value)
    const onIsTransformedChanged = e => setIsTransformed(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const onInteractionSheetNameChange = e => setInteractionSheetName(e.target.value)

    const canSave = [title, contentSource, userId].every(Boolean) && !isLoading

    const onSaveDatasheetClicked = async (e) => {
        if (canSave) {
            await updateDatasheet({ id: datasheet.id, user: userId, title, contentSource, isTransformed })
        }
    }

    const onDeleteDatasheetClicked = async () => {
        await deleteDatasheet({ id: datasheet.id })
    }

    const onCreateInteractionsheetClicked = async (e) => {
        if (canSave) {
            await createInteractionsheet({ dataSheetId: datasheet.id, user: userId, interactionSchemeId, interactionSheetName })
        }
    }

    const created = new Date(datasheet.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(datasheet.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !contentSource ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="bg-red-400 p-2 rounded-md text-blue-800 font-bold ml-5"
                title="Delete"
                onClick={onDeleteDatasheetClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} /> Delete
            </button>
        )
    }

    let createInteractionSheetButton = null
    if (isManager || isAdmin) {
        createInteractionSheetButton = (
            <button
                className="bg-green-400 p-2 rounded-md text-blue-800 font-bold mr-5"
                title="Delete"
                onClick={onCreateInteractionsheetClicked}
            >
                Interaction Sheet Panel
            </button>
        )
    }

    const content = (
        <>
            <p className="bg-red-400">{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <div>
                        <h2 className="font-bold text-2xl text-gray-600">Edit Datasheet #{datasheet.ticket}</h2>
                    </div>
                    <div className="">
                        {createInteractionSheetButton}
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            onClick={onSaveDatasheetClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        {deleteButton}
                        
                        <div className="border border-green-800 border-4 bg-green-200 rounded-md mt-5 p-4">
                            <table>
                                <tr>
                                    <td>
                                        Interaction Sheet Name:
                                    </td>
                                    <td>
                                        <input 
                                            className="w-full"
                                            value={interactionSheetName}
                                            onChange={onInteractionSheetNameChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Interaction Scheme:
                                    </td>
                                    <td>
                                    <select
                                        value={interactionSchemeId}
                                        onChange={(e) => setInteractionSchemeId(e.target.value)}
                                    >
                                        {selectInteractionscheme}
                                    </select>

                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button className="bg-green-400 p-2 rounded-md text-blue-800 font-bold mr-5">
                                            Create Interaction Sheet
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="font-bold" htmlFor="datasheet-title">
                        Title:</label>
                    <input
                        className="w-full p-2 rounded-md"
                        id="datasheet-title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="">
                        <label className="font-bold" htmlFor="datasheet-text">
                            Content Source:</label>
                    </div>
                    <div className="w-full">
                        <textarea
                            // className={`form__input form__input--text ${validTextClass}`}
                            className="w-full p-2 rounded-md"
                            rows={15}
                            id="datasheet-text"
                            name="text"
                            value={contentSource}
                            onChange={onContentSourceChanged}
                        />
                    </div>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="">
                        <label className="font-bold" htmlFor="datasheet-text">
                            Content Article 1:</label>
                    </div>
                    <div className="w-full">
                        <textarea
                            // className={`form__input form__input--text ${validTextClass}`}
                            className="w-full p-2 rounded-md"
                            rows={15}
                            id="datasheet-text"
                            name="text"
                            value={contentArticle1}
                            onChange={onContentSourceChanged}
                        />
                    </div>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <div className="form__divider">
                        <div>
                            <label className="form__label form__checkbox-container" htmlFor="datasheet-completed">
                                IS TRANSFORMED:
                                <input
                                    className="form__checkbox"
                                    id="datasheet-completed"
                                    name="completed"
                                    type="checkbox"
                                    checked={isTransformed}
                                    onChange={onIsTransformedChanged}
                                />
                            </label>
                        </div>

                        <div>
                            <label className="form__label form__checkbox-container" htmlFor="datasheet-username">
                                ASSIGNED TO:</label>
                            <select
                                id="datasheet-username"
                                name="username"
                                className="form__select"
                                value={userId}
                                onChange={onUserIdChanged}
                            >
                                {options}
                            </select>
                        </div>

                    </div>
                    <div className="form__divider">
                        <p className="form__created">Created:<br />{created}</p>
                        <p className="form__updated">Updated:<br />{updated}</p>
                    </div>
                </div>
            </form>
        </>
    )

    return content
}

export default EditDatasheetForm