import { useParams } from 'react-router-dom'
import EditDatasheetForm from './EditDatasheetForm'
import { useGetDatasheetsQuery } from './datasheetsApiSlice'
import { useGetUsersQuery } from '../../users/usersApiSlice'
import useAuth from '../../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../../hooks/useTitle'

const EditDatasheet = () => {
    useTitle('techDatasheets: Edit Datasheet')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { datasheet } = useGetDatasheetsQuery("datasheetsList", {
        selectFromResult: ({ data }) => ({
            datasheet: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!datasheet || !users?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (datasheet.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditDatasheetForm datasheet={datasheet} users={users} />

    return content
}
export default EditDatasheet