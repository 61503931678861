import { BsClipboardCheck, BsFillArchiveFill, BsList, BsGrid1X2Fill, BsPeopleFill } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaCoins, FaCogs, FaIndustry, FaCommentDollar, FaChartPie, FaTools, FaBuilding, FaBullhorn, FaArchive, FaUsersCog } from "react-icons/fa";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import { FaListCheck } from "react-icons/fa6";
import { TbChecklist } from "react-icons/tb";
import { MdFiberNew } from "react-icons/md";
import { AiOutlineHome ,AiOutlineInbox, AiOutlineLink  } from "react-icons/ai";
import { FiCheckCircle, FiSettings } from "react-icons/fi";
import { HiOutlineChartBar } from "react-icons/hi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import useAuth from "../hooks/useAuth";

// start . to be used with log out button
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
// end . to be used with log out button

const linkClasses =
  "flex items-center gap-4 font-light px-1 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-white";
const linkClassesBadge =
  "flex items-center gap-0.5 font-light text-base text-white";
const linkClassesNew =
  "flex items-center gap-8 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-black ";
const linkClassesHeader =
  "flex items-center gap-8 font-light px-3 py-2 mt-4 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-white";

const Sidebar = ({
  isOpen,
  toggleSidebar, // Receive the new props
  projectsData,
  projectOwnersData,
  investorsData,
  transformProjectsData,
}) => {
  // User Utility
  const { username, displayname, isManager, isAdmin, isSdgFinHubCurator } = useAuth();
  const [collapsed, setCollapsed] = useState({});
  const [selectedMenu, setSelectedMenu] = useState(""); // Track selected menu

  // Start . to be used with log out button
  const navigate = useNavigate();

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);
  // End . to be used with log out button


  const handleMenuClick = (path) => {
    setSelectedMenu(path); // Set the selected menu
  };

  const toggleMenu = (key) => {
    setCollapsed((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const menuDataTop = [
    {
      title: "Company Profile App",
      icon: <AiOutlineHome  />,
      key: "home",
      children: [
        { label: "Control Panel", path: "/dash/homecompanyprofile", icon: <IoNewspaperOutline  /> },
        { label: "Company Profile", path: "/dash/homecompanyprofile/companyprofilelist", icon: <IoNewspaperOutline  /> },
        { label: "Posts", path: "/dash/homecompanyprofile/post", icon: <IoNewspaperOutline  /> },
      ],
    },
    {
      title: "Fish Flow",
      icon: <AiOutlineInbox />,
      key: "sourceDocuments",
      children: [
        { label: "Document Migrations", path: "/dash/fishflow/documentmigrations", icon: <AiOutlineInbox /> },
        { label: "Interactions Schemes", path: "/dash/fishflow/interactionschemes", icon: <AiOutlineInbox /> },
        { label: "Data Sheet", path: "/dash/fishflow/datasheets", icon: <AiOutlineInbox /> },
        { label: "Interaction Sheet", path: "/dash/fishflow/interactionsheets", icon: <AiOutlineInbox /> },
      ],
    },
    {
      title: "Renovations",
      icon: <AiOutlineInbox />,
      key: "renovations",
      children: [
        { label: "Tasks", path: "/dash/sj62renovations", icon: <AiOutlineInbox /> },
        // { label: "Interactions Schemes", path: "/dash/fishflow/interactionschemes", icon: <AiOutlineInbox /> },
        // { label: "Data Sheet", path: "/dash/fishflow/datasheets", icon: <AiOutlineInbox /> },
        // { label: "Interaction Sheet", path: "/dash/fishflow/interactionsheets", icon: <AiOutlineInbox /> },
      ],
    }
  ]

  const menuDataBottom = [
    // {
    //   title: "Chitose App",
    //   icon: <FaCogs />,
    //   key: "preprocessing",
    //   children: [
    //     { label: "Data Transformation", path: "/dash/sdgprojectproposals", icon: <FaCogs /> },
    //     // { label: "Send to Evaluation", path: "/dash/sdgprojectproposals", icon: <FaCogs /> },
    //   ],
    // },
    // {
    //   title: "IoT & Integration",
    //   icon: <BsClipboardCheck />,
    //   key: "evaluation",
    //   children: [
    //     { label: "Open Evaluation", path: "/dash/proposalevalutions", icon: <BsClipboardCheck  /> },
    //     { label: "Finalized Evaluations", path: "/dash/finalizedevaluations", icon: <FiCheckCircle  /> },
    //   ],
    // },
    // {
    //   title: "MERN",
    //   icon: <FaIndustry />,
    //   key: "masterData",
    //   children: [
    //     { label: "Enterprises", path: "/dash/enterprises", icon: <FaBuilding /> },
    //     { label: "Investors", path: "/dash/investors", icon: <FaCommentDollar /> },
    //     { label: "Scoring Schemes", path: "/dash/scoringschemes", icon: <TbChecklist /> },
    //     { label: "Campaigns", path: "/dash/campaigns", icon: <FaBullhorn /> },
    //   ],
    // },
    // {
    //   title: "Settings",
    //   icon: <FaChartPie />,
    //   key: "reports",
    //   children: [
    //     { label: "Chart", path: "/dash/chartreports", icon: <FaChartPie /> },
    //     { label: "Scoring Overview", path: "/dash/scoringtables", icon: <HiOutlineChartBar  /> },
    //   ],
    // },
    // {
    //   title: "Archives",
    //   icon: <BsFillArchiveFill />,
    //   key: "archives",
    //   children: [
    //     { label: "TRANSFORM", path: "/dash/transformroundtwo", icon: <FaArchive /> },
    //   ],
    // },
    // {
    //   title: "Settings",
    //   icon: <FaTools />,
    //   key: "settings",
    //   children: [
    //     { label: "Board Curators", path: "/dash/boardcurators", icon: <FaUsersCog /> },
    //     { label: "API Settings", path: "/dash/settings", icon: <FiSettings /> },
    //   ],
    // },
    // {
    //   title: "Visit websites",
    //   icon: <AiOutlineLink />,
    //   key: "websites",
    //   children: [
    //     { label: "SDG Investment", path: "/dash/boardcurators", icon: <AiOutlineLink  /> },
    //     { label: "Mudah Technology", path: "/dash/settings", icon: <AiOutlineLink  /> },
    //   ],
    // },
  ];

  return (
    <>
      <div
      className="bg-green-900 flex flex-col h-full p-3 text-white fixed top-0 left-0 z-50"
      style={{ width: isOpen ? "16rem" : "3rem" }}
    >
      {" "}
      {/* Adjust the width here */}
      {/* <div className="flex flex-col justify-between h-full">
        <div className={`p-2 ${isOpen ? 'self-end' : 'self-center'} transition-all duration-300`}> */}
      <div className="relative flex flex-col h-full">
        {/* Position the toggle button absolutely */}
        <div className="absolute top-0 right-0 -mt-5.5">
          {/* Menu Icon or Toggle Button */}
          <button onClick={toggleSidebar} className="p-2">
            {/* { isOpen && <GoSidebarCollapse  fontSize={15} />} */}
            {isOpen ? (
              <GoSidebarExpand fontSize={15} />
            ) : (
              <GoSidebarCollapse fontSize={15} />
            )}
          </button>
        </div>

        {isOpen && (
          <>
            <div className="flex items-center gap-2 px-2 py-3 text-white border-b border-b-gray-400">
              <FaCoins fontSize={24} />
              <span className="text-neutral-100 text-lg">
                Mudah Technology 1.0.20240416.1832
              </span>
            </div>

            {/* <div className="px-2 py-3 mb-3 border-b border-b-gray-400"> */}
            {/* <div className="px-2 py-3 mb-3 border-b border-b-gray-400 border border-red-500 text-lg sm:text-base"> */}
            <div className="px-2 py-3 mb-3 border-b border-b-gray-400 text-base">
              Welcome, {displayname}!
            </div>

            <div className="flex py-4 flex-col gap-0.5 overflow-y-scroll scrollbar-webkit">
              <ul>
                <li className={linkClasses}>
                  <span className="text-lg">Home</span>
                </li>
                <li className={linkClasses}>
                  <BsGrid1X2Fill className="text-lg" />{" "}
                  <span className="text-md">
                    <NavLink to="/dash">Home</NavLink>
                  </span>
                </li>

                {/* Group Menu Company Profile */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Company Profile App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile" className="w-32">
                      Control Panel
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/companyprofilelist" className="w-32">
                      Company Profile
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/post" className="w-32">
                      Posts
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Nebraska</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/kitchen/kulkasitems" className="w-32">
                      Isi Kulkas
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/kitchen/belanjaitems" className="w-32">
                      Belanja
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/kitchen/kulkassections" className="w-32">
                      Kulkas Section
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Mudah AI Tools</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/aitools" className="w-32">
                      Ask AI Any
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/aitools" className="w-32">
                      GPT API
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Fish Flow</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/fishflow/documentmigrations" className="w-32">
                      Document Migrations
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/fishflow/interactionschemes" className="w-32">
                      Interactions Schemes
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/fishflow/datasheets" className="w-32">
                      Data Sheet
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}
                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/fishflow/interactionsheets" className="w-32">
                      Interaction Sheet
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Chitose App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/chitose" className="w-32">
                      Import Excels
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu */}
                <li className={linkClassesHeader}>
                  <span className="text-md">SDG Fin Hub App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <FaChartPie className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/analytics">Go to the app!</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">IoT & Integration</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/homeiotintegration">
                        IoT & Integration
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">MERN</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/homereactcode">
                        REACT 18
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/postreducerexample" className="w-32">
                      Post useReducer Example
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/poststateexample" className="w-32">
                      Post useState Example
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        MongoDB Atlas
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        Node JS
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        Tailwind CSS & CSS
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">Settings</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <FaChartPie />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/settings">API settings</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsPeopleFill />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/boardcurator">Help & Supports</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                    <li className={linkClasses}>
                      <BsPeopleFill />{" "}
                      <span className="text-md">
                        <Link style={{ color:"yellow",  }} to="/dash/users">View User Settings</Link>
                      </span>
                    </li>
                )}

                <li>
                  {/* <FaSignOutAlt className="text-lg" />{" "} */}
                  <span className="flex mt-4 ml-4 text-md">
                    <button onClick={sendLogout}>Sign Out</button>
                  </span>
                </li>
              </ul>
              <div className="mt-10 text-gray-600">
                <small><i>AI can make mistakes. Consider checking important information</i></small>
                <small><i>AI can make mistakes. Consider checking important information</i></small>
              </div>

            </div>
          </>
        )}
      </div>
      </div>

      <div className={`bg-neutral-900 flex flex-col h-full p-2 text-white fixed top-0 left-0 z-50`} style={{ width: isOpen ? "16rem" : "3rem" }}>
      {/* div utama */}
      <div className="relative flex flex-col h-full justify-between">
        {/* Sidebar toggle button */}
        <div className="flex flex-col items-center absolute top-2.5 right-0">
          <button id="menu-button" onClick={toggleSidebar} className="p-2 bg-neutral-900 font-bold">
            <BsList fontSize={20} />
          </button>

          {/* div ketika side bar collapsed !isOpen*/}
          {!isOpen && (
            <div
              id="vertical-label"
              className="writing-mode-vertical flex items-center justify-center h-full text-center pl-1"
              style={{ writingMode: "vertical-lr", textOrientation: "mixed", transform: "rotate(180deg)"}}
            >
              Click the list to see the menu
            </div>
          )}
        </div>


        {/* Percobaan untuk flex cols supaya numpuk ke atas fragmen blue di side bar */}
        <div className="flex flex-col">
          {/* 0. Side Bar Menu Header, title is here */}
          {isOpen && (
            <>
              <div className="flex flex-col">
                {/* div for application title */}
                <div className="flex items-center gap-2 px-2 py-3 text-white">
                  <img src="/img/sdg-white.png" alt="sdg" className="w-8" />
                  <span className="text-neutral-100 text-md">Mudah Technology 2.0</span>
                </div>
              </div>           
            </>
          )}


          {/* 1. Side Bar Menu TOP Part */}
          {isOpen && (
            <>
              <div className="flex flex-col">
                {/* <div className="flex items-center gap-2 px-2 py-3 text-white border-b border-b-gray-400">
                  <img src="/img/sdg-white.png" alt="sdg" className="w-8" />
                  <span className="text-neutral-100 text-md">SDG Financing Hub-AI</span>
                </div> */}


                {/* div for menu side bar TOP Part*/}
                <ul className="flex flex-col gap-1 mt-2">
                  {menuDataTop.map((menu) => (
                    <li key={menu.key} className="cursor-pointer" onClick={() => toggleMenu(menu.key)}>
                      <div className={linkClasses}>
                        {menu.icon}
                        <span>{menu.title}</span>
                      </div>
                      {collapsed[menu.key] && (
                      <ul className="pl-4">
                        {menu.children.map((child, index) => (
                          <li
                            key={index}
                            className={`${linkClasses} ${selectedMenu === child.path ? "bg-neutral-700" : ""}`} // Highlight selected menu
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent collapsing the parent
                              handleMenuClick(child.path);
                            }}
                          >
                          <NavLink
                            to={child.path}
                            className={`w-full flex items-center gap-2 p-1 rounded-sm ${
                              selectedMenu === child.path
                                ? "text-white bg-neutral-700" // Selected menu: white text with a slightly darker background
                                : "text-gray-300 hover:text-white hover:bg-neutral-800" // Normal and hover states
                            }`}
                          >
                            {child.icon}
                            {child.label}
                          </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {/* <>
            <hr></hr>
          </> */}

          {/* 2. Side Bar Menu BOTTOM Part */}
          {isOpen && (
            <>
              <div className="flex flex-col">
                {/* <span className="text-sm text-gray-400">Campaign: {selectedCampaign}</span>
                <span className="text-sm text-gray-400">Scheme Name: {selectedSchemeName}</span>

                {isSuccess && (
                  <div className="text-gray-800">
                    <select className="rounded-md text-sm mt-5" id="campaignSelect" value={selectedCampaignStatus} onChange={handleSelectCampaignStatus}>
                      <option value="open" >Open campaign</option>
                      <option value="closed" >Closed campaign</option>
                    </select>

                    <select className="rounded-md text-sm mt-5" id="campaignSelect" value={selectedCampaign} onChange={handleSelectChange}>
                      <option value="" disabled>--Select a campaign--</option>
                      {campaignsList.map((campaign, index) => (
                        <option key={index} value={campaign.title}>
                          {campaign.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )} */}
              
                {/* div for menu side bar */}
                <ul className="flex flex-col gap-1 mt-2">
                  {menuDataBottom.map((menu) => (
                    <li key={menu.key} className="cursor-pointer" onClick={() => toggleMenu(menu.key)}>
                      <div className={linkClasses}>
                        {menu.icon}
                        <span>{menu.title}</span>
                      </div>
                      {collapsed[menu.key] && (
                      <ul className="pl-4">
                        {menu.children.map((child, index) => (
                          <li
                            key={index}
                            className={`${linkClasses} ${selectedMenu === child.path ? "bg-neutral-700" : ""}`} // Highlight selected menu
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent collapsing the parent
                              handleMenuClick(child.path);
                            }}
                          >
                          <NavLink
                            to={child.path}
                            className={`w-full flex items-center gap-2 p-1 rounded-sm ${
                              selectedMenu === child.path
                                ? "text-white bg-neutral-700" // Selected menu: white text with a slightly darker background
                                : "text-gray-300 hover:text-white hover:bg-neutral-800" // Normal and hover states
                            }`}
                          >
                            {child.icon}
                            {child.label}
                          </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}        
        
          {/* 3. Side Bar Menu FOOTER Part */}
          {isOpen && (
            <>
              <div className="pt-10">
                <button  onClick={sendLogout}>Sign Out</button>
              </div>

              <div id="version" className=" text-gray-600 text-sm text-center mt-4">
                  <div>
                    Version 3
                  </div>              
                  Version 4 is coming
              </div>
            </>
          )}
        </div>

      </div>
      </div>
    </>
  );
};

export default Sidebar;
