import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice"

const interactionschemesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = interactionschemesAdapter.getInitialState()

export const interactionschemesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInteractionschemes: builder.query({
            query: () => ({
                url: '/interactionschemes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedInteractionschemes = responseData.map(interactionscheme => {
                    interactionscheme.id = interactionscheme._id
                    return interactionscheme
                });
                return interactionschemesAdapter.setAll(initialState, loadedInteractionschemes)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Interactionscheme', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Interactionscheme', id }))
                    ]
                } else return [{ type: 'Interactionscheme', id: 'LIST' }]
            }
        }),
        addNewInteractionscheme: builder.mutation({
            query: initialInteractionscheme => ({
                url: '/interactionschemes',
                method: 'POST',
                body: {
                    ...initialInteractionscheme,
                }
            }),
            invalidatesTags: [
                { type: 'Interactionscheme', id: "LIST" }
            ]
        }),
        updateInteractionscheme: builder.mutation({
            query: initialInteractionscheme => ({
                url: '/interactionschemes',
                method: 'PATCH',
                body: {
                    ...initialInteractionscheme,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Interactionscheme', id: arg.id }
            ]
        }),
        deleteInteractionscheme: builder.mutation({
            query: ({ id }) => ({
                url: `/interactionschemes`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Interactionscheme', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetInteractionschemesQuery,
    useAddNewInteractionschemeMutation,
    useUpdateInteractionschemeMutation,
    useDeleteInteractionschemeMutation,
} = interactionschemesApiSlice

// returns the query result object
export const selectInteractionschemesResult = interactionschemesApiSlice.endpoints.getInteractionschemes.select()

// creates memoized selector
const selectInteractionschemesData = createSelector(
    selectInteractionschemesResult,
    interactionschemesResult => interactionschemesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllInteractionschemes,
    selectById: selectInteractionschemeById,
    selectIds: selectInteractionschemeIds
    // Pass in a selector that returns the interactionschemes slice of state
} = interactionschemesAdapter.getSelectors(state => selectInteractionschemesData(state) ?? initialState)