import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetInteractionschemesQuery } from './interactionschemesApiSlice'
import { memo } from 'react'

const Interactionscheme = ({ interactionschemeId, index }) => {

    const { interactionscheme } = useGetInteractionschemesQuery("interactionschemesList", {
        selectFromResult: ({ data }) => ({
            interactionscheme: data?.entities[interactionschemeId]
        }),
    })

    const navigate = useNavigate()

    if (interactionscheme) {
        const created = new Date(interactionscheme.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(interactionscheme.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/fishflow/interactionschemes/${interactionschemeId}`)

        return (
            <tr className="table__row">
                <td className="table__cell interactionscheme__title">{index + 1}</td>
                <td className="table__cell interactionscheme__title">{interactionscheme.title}</td>
                <td className="table__cell interactionscheme__title">{interactionscheme.responseType}</td>
                <td className="table__cell interactionscheme__status">
                    {interactionscheme.isActive
                        ? <span className="interactionscheme__status--completed">Active</span>
                        : <span className="interactionscheme__status--open">Not Active</span>
                    }
                </td>
                <td className="table__cell interactionscheme__created">{created}</td>
                <td className="table__cell interactionscheme__updated">{updated}</td>
                <td className="table__cell interactionscheme__username">{interactionscheme.username}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedInteractionscheme = memo(Interactionscheme)

export default memoizedInteractionscheme