import NewSj62renovationForm from './NewSj62renovationForm'
import { useGetUsersQuery } from '../../users/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../../hooks/useTitle'

const NewSj62renovation = () => {
    useTitle('techSj62renovations: New Sj62renovation')

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!users?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewSj62renovationForm users={users} />

    return content
}
export default NewSj62renovation