import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetDocumentmigrationsQuery } from './documentmigrationsApiSlice'
import { memo } from 'react'

const Documentmigration = ({ documentmigrationId }) => {

    const { documentmigration } = useGetDocumentmigrationsQuery("documentmigrationsList", {
        selectFromResult: ({ data }) => ({
            documentmigration: data?.entities[documentmigrationId]
        }),
    })

    const navigate = useNavigate()

    if (documentmigration) {
        const created = new Date(documentmigration.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(documentmigration.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/documentmigrations/${documentmigrationId}`)

        return (
            <tr className="table__row">
                <td className="table__cell documentmigration__status">
                    {documentmigration.completed
                        ? <span className="documentmigration__status--completed">Completed</span>
                        : <span className="documentmigration__status--open">Open</span>
                    }
                </td>
                <td className="table__cell documentmigration__created">{created}</td>
                <td className="table__cell documentmigration__updated">{updated}</td>
                <td className="table__cell documentmigration__title">{documentmigration.title}</td>
                <td className="table__cell documentmigration__username">{documentmigration.username}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedDocumentmigration = memo(Documentmigration)

export default memoizedDocumentmigration