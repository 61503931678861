import { useGetDocumentmigrationsQuery } from "./documentmigrationsApiSlice"
import Documentmigration from "./Documentmigration"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const DocumentmigrationsList = () => {
    useTitle('techDocumentmigrations: Documentmigrations List')

    const { username, isManager, isAdmin } = useAuth()

    const {
        data: documentmigrations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDocumentmigrationsQuery('documentmigrationsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = documentmigrations

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(documentmigrationId => entities[documentmigrationId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(documentmigrationId => <Documentmigration key={documentmigrationId} documentmigrationId={documentmigrationId} />)

        content = (
            <table className="table table--documentmigrations">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th documentmigration__status">Username</th>
                        <th scope="col" className="table__th documentmigration__created">Created</th>
                        <th scope="col" className="table__th documentmigration__updated">Updated</th>
                        <th scope="col" className="table__th documentmigration__title">Title</th>
                        <th scope="col" className="table__th documentmigration__username">Owner</th>
                        <th scope="col" className="table__th documentmigration__edit">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        )
    }

    return content
}
export default DocumentmigrationsList