import { useParams } from 'react-router-dom'
import EditSj62renovationForm from './EditSj62renovationForm'
import { useGetSj62renovationsQuery } from './sj62renovationsApiSlice'
import { useGetUsersQuery } from '../../users/usersApiSlice'
import useAuth from '../../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../../hooks/useTitle'

const EditSj62renovation = () => {
    useTitle('techSj62renovations: Edit Sj62renovation')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { sj62renovation } = useGetSj62renovationsQuery("sj62renovationsList", {
        selectFromResult: ({ data }) => ({
            sj62renovation: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!sj62renovation || !users?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (sj62renovation.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditSj62renovationForm sj62renovation={sj62renovation} users={users} />

    return content
}
export default EditSj62renovation