// import { useRef, useState, useEffect } from 'react'
// import { useNavigate, Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { setCredentials } from './authSlice'
// import { useLoginMutation } from './authApiSlice'
// import usePersist from '../../hooks/usePersist'
// import useTitle from '../../hooks/useTitle'
// import PulseLoader from 'react-spinners/PulseLoader'

// const Login = () => {
//     useTitle('User Login')

//     const userRef = useRef()
//     const errRef = useRef()
//     const [username, setUsername] = useState('')
//     const [password, setPassword] = useState('')
//     const [errMsg, setErrMsg] = useState('')
//     const [persist, setPersist] = usePersist()

//     const navigate = useNavigate()
//     const dispatch = useDispatch()

//     const [login, { isLoading }] = useLoginMutation()

//     useEffect(() => {
//         userRef.current.focus()
//     }, [])

//     useEffect(() => {
//         setErrMsg('');
//     }, [username, password])


//     const handleSubmit = async (e) => {
//         e.preventDefault()
//         try {
//             const { accessToken } = await login({ username, password }).unwrap()
//             dispatch(setCredentials({ accessToken }))
//             setUsername('')
//             setPassword('')
//             console.log(">>> " + accessToken)
//             navigate('/dash')
//         } catch (err) {
//             if (!err.status) {
//                 setErrMsg('No Server Response');
//             } else if (err.status === 400) {
//                 setErrMsg('Missing Username or Password');
//             } else if (err.status === 401) {
//                 setErrMsg('Unauthorized');
//             } else {
//                 setErrMsg(err.data?.message + " <sdg> Error in Connection to API. Check your base URL Ref: 2001");
//             }
//             // i commented below because it cause error user ref, 
//             // ternyata error gara2 react redux version
//             // rabu malam di uncomment again
//             errRef.current.focus();
//         }
//     }

//     const handleUserInput = (e) => setUsername(e.target.value)
//     const handlePwdInput = (e) => setPassword(e.target.value)
//     const handleToggle = () => setPersist(prev => !prev)

//     const errClass = errMsg ? "errmsg" : "offscreen"

//     if (isLoading) return <PulseLoader color={"#FFF"} />

//     const content = (
//         <section style={{ backgroundColor:"burlywood", padding:"5px" }}>
//             <small>Login.js</small>
//             <article>
//                 This page all in ONE page only
//             </article>
//             <header style={{ backgroundColor:"aquamarine" }}>
//                 <small>header block</small>
//                 <h1>User Sign In</h1>
//             </header>
//             <main style={{ backgroundColor:"aquamarine", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"20px" }}>
//                 <small>main block</small>
//                 <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>

//                 <form className="form" onSubmit={handleSubmit}>
                    
//                     <div  style={{ margin: "20px 20px" }}>
//                         <label htmlFor="username">Username:</label>
//                         <input
//                             className="form__input"
//                             type="text"
//                             id="username"
//                             ref={userRef}
//                             value={username}
//                             onChange={handleUserInput}
//                             autoComplete="off"
//                             placeholder='User Name'
//                             required
//                         />
//                     </div>

//                     <div style={{ margin: "20px 20px" }}>
//                         <label htmlFor="password">Password:</label>
//                         <input
//                             className="form__input"
//                             type="password"
//                             id="password"
//                             onChange={handlePwdInput}
//                             value={password}
//                             placeholder='Password'
//                             required
//                         />
//                     </div>

//                     <div  style={{ margin: "20px 20px", display:"flex", flexDirection:"column", border:"solid 1px"}}>
//                         <button style={{ width:"300px" }}>Sign In</button>

//                         <label htmlFor="persist" style={{ width:"300px" }}>
//                             <input
//                                 type="checkbox"
//                                 className="form__checkbox"
//                                 id="persist"
//                                 onChange={handleToggle}
//                                 checked={persist}
//                                 />
//                             Trust This Device
//                         </label>
//                     </div>
//                 </form>
//             </main>
//             <footer style={{ backgroundColor:"burlywood" }}>
//                 <small>footer block</small>
//                 <Link to="/">Back to Home</Link>
//             </footer>
//         </section>
//     )

//     return content
// }
// export default Login

import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import usePersist from '../../hooks/usePersist';
import useTitle from '../../hooks/useTitle';
import PulseLoader from 'react-spinners/PulseLoader';

const Login = () => {
    useTitle('Admin Login | PT Teknologi Mudah Selaras');

    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [persist, setPersist] = usePersist();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { accessToken } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');
            navigate('/dash');
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    };

    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const handleToggle = () => setPersist((prev) => !prev);

    if (isLoading) return <PulseLoader color={"#3b82f6"} />;

    return (
        <section className="min-h-screen flex items-center justify-center bg-gradient-to-br from-teal-100 to-teal-200 p-4">
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold text-teal-600 text-center">Admin Login</h1>
                <p className="text-sm text-gray-500 text-center mt-2">PT Teknologi Mudah Selaras</p>

                {errMsg && (
                    <p
                        ref={errRef}
                        className="bg-red-100 text-red-600 border border-red-400 p-2 rounded mt-4"
                        aria-live="assertive"
                    >
                        {errMsg}
                    </p>
                )}

                <form onSubmit={handleSubmit} className="mt-6">
                    <div className="mb-4">
                        <label htmlFor="username" className="block text-gray-700">Username</label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            value={username}
                            onChange={handleUserInput}
                            placeholder="Enter your username"
                            className="w-full p-2 mt-1 border rounded focus:outline-none focus:ring focus:ring-teal-300"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePwdInput}
                            placeholder="Enter your password"
                            className="w-full p-2 mt-1 border rounded focus:outline-none focus:ring focus:ring-teal-300"
                            required
                        />
                    </div>

                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="persist"
                            checked={persist}
                            onChange={handleToggle}
                            className="mr-2"
                        />
                        <label htmlFor="persist" className="text-sm text-gray-600">Trust This Device</label>
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-teal-600 text-white py-2 rounded hover:bg-teal-700 focus:outline-none focus:ring focus:ring-teal-300"
                    >
                        Sign In
                    </button>
                </form>

                <footer className="mt-6 text-center">
                    <Link to="/" className="text-teal-500 hover:underline">Back to Home</Link>
                </footer>
            </div>
        </section>
    );
};

export default Login;
