import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewDatasheetMutation } from "./datasheetsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewDatasheetForm = ({ users }) => {

    const [addNewDatasheet, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewDatasheetMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [contentSource, setContentSource] = useState('')
    // const [userId, setUserId] = useState(users[0].id)
    const [userId, setUserId] = useState("653f54be13d7ab2243a410f2") //force to admin

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setContentSource('')
            setUserId('')
            navigate('/dash/fishflow/datasheets')
        }
    }, [isSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onContentSourceChanged = e => setContentSource(e.target.value)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, contentSource, userId].every(Boolean) && !isLoading

    const onSaveDatasheetClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewDatasheet({ user: userId, title, contentSource })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !contentSource ? "form__input--incomplete" : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveDatasheetClicked}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <h2 className="font-bold text-2xl text-gray-600">New Datasheet</h2>
                    <div className="form__action-buttons">
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="title">
                        Title:</label>
                    <input
                        className="w-full p-2 rounded-md"
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        Content Source:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        rows={15}
                        id="text"
                        name="text"
                        value={contentSource}
                        onChange={onContentSourceChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label form__checkbox-container" htmlFor="username">
                        ASSIGNED TO:</label>
                    <select
                        id="username"
                        name="username"
                        className="form__select"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>
            </form>
        </>
    )

    return content
}

export default NewDatasheetForm