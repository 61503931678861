import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetDatasheetsQuery } from './datasheetsApiSlice'
import { memo } from 'react'

const Datasheet = ({ datasheetId, index }) => {

    const { datasheet } = useGetDatasheetsQuery("datasheetsList", {
        selectFromResult: ({ data }) => ({
            datasheet: data?.entities[datasheetId]
        }),
    })

    const navigate = useNavigate()

    if (datasheet) {
        const created = new Date(datasheet.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(datasheet.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/fishflow/datasheets/${datasheetId}`)

        return (
            <tr className="table__row">
                <td className="table__cell datasheet__title">{index + 1}</td>
                <td className="table__cell datasheet__title">{datasheet.title}</td>
                <td className="table__cell datasheet__title">{datasheet.contentSource.replace(/\n/g, '').substring(0, 70)}</td>
                <td className="table__cell datasheet__status">
                    {datasheet.isTransformed
                        ? <span className="datasheet__status--completed">Transformed</span>
                        : <span className="datasheet__status--open">Open</span>
                    }
                </td>
                <td className="table__cell datasheet__created">{created}</td>
                <td className="table__cell datasheet__updated">{updated}</td>
                <td className="table__cell datasheet__username">{datasheet.username}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedDatasheet = memo(Datasheet)

export default memoizedDatasheet