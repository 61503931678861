import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice"

const sj62renovationsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = sj62renovationsAdapter.getInitialState()

export const sj62renovationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSj62renovations: builder.query({
            query: () => ({
                url: '/sj62renovations',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedSj62renovations = responseData.map(sj62renovation => {
                    sj62renovation.id = sj62renovation._id
                    return sj62renovation
                });

                // Calculate totals
                const totalCost = loadedSj62renovations.reduce(
                    (sum, item) => sum + (parseFloat(item.cost) || 0), 
                    0
                );
                const totalBudget = loadedSj62renovations.reduce(
                    (sum, item) => sum + (parseFloat(item.budget) || 0), 
                    0
                );
                const balance = totalBudget - totalCost;
                
                return {
                    ...sj62renovationsAdapter.setAll(initialState, loadedSj62renovations),
                    totals: { totalCost, totalBudget, balance } // Include totals at the top level
                };
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Sj62renovation', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Sj62renovation', id }))
                    ]
                } else return [{ type: 'Sj62renovation', id: 'LIST' }]
            }
        }),
        addNewSj62renovation: builder.mutation({
            query: initialSj62renovation => ({
                url: '/sj62renovations',
                method: 'POST',
                body: {
                    ...initialSj62renovation,
                }
            }),
            invalidatesTags: [
                { type: 'Sj62renovation', id: "LIST" }
            ]
        }),
        updateSj62renovation: builder.mutation({
            query: initialSj62renovation => ({
                url: '/sj62renovations',
                method: 'PATCH',
                body: {
                    ...initialSj62renovation,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Sj62renovation', id: arg.id }
            ]
        }),
        deleteSj62renovation: builder.mutation({
            query: ({ id }) => ({
                url: `/sj62renovations`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Sj62renovation', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetSj62renovationsQuery,
    useAddNewSj62renovationMutation,
    useUpdateSj62renovationMutation,
    useDeleteSj62renovationMutation,
} = sj62renovationsApiSlice

// returns the query result object
export const selectSj62renovationsResult = sj62renovationsApiSlice.endpoints.getSj62renovations.select('sj62renovationsList')

// creates memoized selector
const selectSj62renovationsData = createSelector(
    selectSj62renovationsResult,
    sj62renovationsResult => sj62renovationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSj62renovations,
    selectById: selectSj62renovationById,
    selectIds: selectSj62renovationIds
    // Pass in a selector that returns the sj62renovations slice of state
} = sj62renovationsAdapter.getSelectors(state => selectSj62renovationsData(state) ?? initialState)

// Selector to get totals
export const selectSj62renovationTotals = createSelector(
    selectSj62renovationsResult,
    sj62renovationsResult => {
        if (sj62renovationsResult?.status === 'fulfilled') {
            console.log("Totals data:", sj62renovationsResult?.data?.totals); // Debugging output
            return sj62renovationsResult?.data?.totals ?? { totalCost: 0, totalBudget: 0, balance: 0 };
        } else {
            console.log("sj62renovationsResult status:", sj62renovationsResult?.status); // Debugging output
            return { totalCost: -1, totalBudget: -1, balance: -1 };
        }
    }
);


// import {
//     createSelector,
//     createEntityAdapter
// } from "@reduxjs/toolkit";
// import { apiSlice } from "../../../app/api/apiSlice";

// const sj62renovationsAdapter = createEntityAdapter({
//     sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
// });

// const initialState = sj62renovationsAdapter.getInitialState();

// export const sj62renovationsApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         getSj62renovations: builder.query({
//             query: () => ({
//                 url: '/sj62renovations',
//                 validateStatus: (response, result) => {
//                     return response.status === 200 && !result.isError;
//                 },
//             }),
//             transformResponse: responseData => {
//                 const loadedSj62renovations = responseData.map(sj62renovation => {
//                     sj62renovation.id = sj62renovation._id;
//                     return sj62renovation;
//                 });
            
//                 const totalCost = loadedSj62renovations.reduce(
//                     (sum, item) => sum + (parseFloat(item.cost) || 0), 
//                     0
//                 );
//                 const totalBudget = loadedSj62renovations.reduce(
//                     (sum, item) => sum + (parseFloat(item.budget) || 0), 
//                     0
//                 );
//                 const balance = totalBudget - totalCost;
            
//                 const transformedResponse = {
//                     ...sj62renovationsAdapter.setAll(initialState, loadedSj62renovations),
//                     totals: { totalCost, totalBudget, balance }
//                 };
            
//                 console.log("Transformed Response bagus sih:", transformedResponse);
//                 return transformedResponse;
//             },
//             providesTags: (result, error, arg) => {
//                 if (result?.ids) {
//                     return [
//                         { type: 'Sj62renovation', id: 'LIST' },
//                         ...result.ids.map(id => ({ type: 'Sj62renovation', id }))
//                     ];
//                 } else return [{ type: 'Sj62renovation', id: 'LIST' }];
//             }
//         }),
//         addNewSj62renovation: builder.mutation({
//             query: initialSj62renovation => ({
//                 url: '/sj62renovations',
//                 method: 'POST',
//                 body: {
//                     ...initialSj62renovation,
//                 }
//             }),
//             invalidatesTags: [
//                 { type: 'Sj62renovation', id: "LIST" }
//             ]
//         }),
//         updateSj62renovation: builder.mutation({
//             query: initialSj62renovation => ({
//                 url: '/sj62renovations',
//                 method: 'PATCH',
//                 body: {
//                     ...initialSj62renovation,
//                 }
//             }),
//             invalidatesTags: (result, error, arg) => [
//                 { type: 'Sj62renovation', id: arg.id }
//             ]
//         }),
//         deleteSj62renovation: builder.mutation({
//             query: ({ id }) => ({
//                 url: `/sj62renovations`,
//                 method: 'DELETE',
//                 body: { id }
//             }),
//             invalidatesTags: (result, error, arg) => [
//                 { type: 'Sj62renovation', id: arg.id }
//             ]
//         }),
//     }),
// });

// export const {
//     useGetSj62renovationsQuery,
//     useAddNewSj62renovationMutation,
//     useUpdateSj62renovationMutation,
//     useDeleteSj62renovationMutation,
// } = sj62renovationsApiSlice;

// // returns the query result object
// export const selectSj62renovationsResult = sj62renovationsApiSlice.endpoints.getSj62renovations.select('sj62renovationsList');

// const logSelectResult = createSelector(
//     state => state, // Log the full state
//     selectSj62renovationsResult,
//     (fullState, result) => {
//         console.log("Full State:", fullState);
//         console.log("Raw Selector Result:", result);
//         return result;
//     }
// );

// // creates memoized selector
// const selectSj62renovationsData = createSelector(
//     selectSj62renovationsResult,
//     sj62renovationsResult => sj62renovationsResult.data // normalized state object with ids & entities
// );

// // Get selectors for entities
// export const {
//     selectAll: selectAllSj62renovations,
//     selectById: selectSj62renovationById,
//     selectIds: selectSj62renovationIds
// } = sj62renovationsAdapter.getSelectors(state => selectSj62renovationsData(state)?.entities ?? initialState);

// // Selector to get totals
// export const selectSj62renovationTotals = createSelector(
//     logSelectResult,
//     sj62renovationsResult => sj62renovationsResult?.data?.totals ?? { totalCost: -1, totalBudget: -1, balance: -1 }
// );
